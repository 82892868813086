import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { cptCodeInfo } from '@/modules/patients/constants/cptCodeInfo';
import { LinearProgress } from '@mui/material';
import { AlertTriangle } from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import { useGetList } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { get } from 'lodash';
import { Separator } from '@/modules/ui/components/separator';

function ProgressBar(props) {
  const { service } = props;
  const codeInfo = get(cptCodeInfo, service.cpt_code);

  if (!service) {
    return null;
  }

  const primaryText = () => {
    let primaryTextVar = '';
    if (codeInfo.type === 'days') {
      primaryTextVar = `${Math.min(
        Math.round(service.value),
        service.max_value,
      )} / ${Math.round(service.max_value)} ${
        codeInfo.units ? `(${codeInfo.units})` : ''
      }`;
    } else if (codeInfo.type === 'time') {
      primaryTextVar = `${Math.round(service.value / 60)} / ${Math.round(
        service.max_value / 60,
      )} ${codeInfo.units ? `(${codeInfo.units})` : ''}`;
    }
    return primaryTextVar;
  };

  return (
    <div>
      <div className="flex flex-col gap-1">
        <div>{primaryText()}</div>
        <div>
          <LinearProgress
            variant="determinate"
            value={service.progress * 100}
            sx={{ height: 10, borderRadius: 5 }}
          />
        </div>
      </div>
    </div>
  );
}

const incompatibleCodes = {
  rpm_99091: '99457',
  rpm_99457_99458: '99091',
  ccm_99490_99439: '99491',
  ccm_99491_99437: '99490',
  pcm_99424_99425: '99426',
  pcm_99426_99427: '99424',
};

function IncompatibleBillingWarning(props) {
  const { patient_id, className = '' } = props;
  const startDate = DateTime.now().minus({ days: 30 }).toISODate();

  const monitoringType = useWatch({ name: 'type' });
  const description = useWatch({ name: 'description' });

  const { data } = useGetList('services', {
    pagination: { page: 1, perPage: 10 },
    filter: {
      'patient_id[eq]': patient_id,
      'start_date[gte]': startDate,
    },
  });

  if (monitoringType) {
    const incompatibleCode = incompatibleCodes[monitoringType];
    const incompatibleService = data?.find(
      (service: any) =>
        service.cpt_code === incompatibleCode && service.progress > 0,
    );

    if (description && description.length > 0 && monitoringType === 'note') {
      return (
        <div className={className}>
          <Alert variant="warning">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Note only mode</AlertTitle>
            <AlertDescription>
              This note will not count as billable time.
            </AlertDescription>
          </Alert>
        </div>
      );
    } else if (monitoringType && incompatibleService) {
      return (
        <div className={className}>
          <Alert variant="warning">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Potential incompatible work</AlertTitle>
            <AlertDescription>
              The type of work potentially conflicts with an existing service.
              Both may not be billed in the same calendar month.
            </AlertDescription>
            <Separator />
            <AlertDescription className="mt-1 text-gray-500 flex flex-col gap-1">
              <div>
                {incompatibleService.start_date} -{' '}
                {incompatibleService.end_date}
              </div>
              <div>{incompatibleService.cpt_code}</div>
              <div>
                <ProgressBar service={incompatibleService} />
              </div>
            </AlertDescription>
          </Alert>
        </div>
      );
    }
  }

  return null;
}

export default IncompatibleBillingWarning;
