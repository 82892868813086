import DataTableReferenceFilter from '@/modules/data-table/components/data-table-reference-filter';
import { get } from 'lodash';
import React from 'react';
import { useListContext } from 'react-admin';

export const ProviderLocationFilter = ({
  source = 'onboarding_appointment_location_id[eq]',
  label = 'Expected Onboarding Location',
}) => {
  const { filterValues } = useListContext();

  if (get(filterValues, 'tenant_id[eq]')) {
    return (
      <DataTableReferenceFilter
        resource="locations"
        column={source}
        title={label}
        optionText="name"
        customFilter={(name) => ({
          ...(name &&
            name.length > 1 && {
              q: { field: 'name', value: name },
            }),
          'tenant_id[eq]': filterValues['tenant_id[eq]'],
        })}
      />
    );
  }

  return null;
};
