import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import DataTableReferenceFilter from '@/modules/data-table/components/data-table-reference-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import { EnrollmentEditButton } from '@/modules/enrollment/components/EnrollmentEditButton';
import { EnrollmentExportButton } from '@/modules/enrollment/components/EnrollmentExportButton';
import AppTitle from '@/modules/layout/components/app-title';
import { patientStatus } from '@/modules/patients/constants/patientStatus';
import { primaryConditions } from '@/modules/patients/constants/primaryConditions';
import { DateTimeProviderTZField } from '@/modules/ra-ui/components/DateTimeProviderTZField';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import { Box } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';

import { DataTableFilterSingle } from '@/modules/data-table/components/data-table-filter-single';
import { EnrollmentListConfig } from '@/modules/enrollment/components/EnrollmentListConfig';
import {
  EnrollmentQueueProvider,
  useEnrollmentQueue,
} from '@/modules/enrollment/components/EnrollmentQueueProvider';
import {
  ArrayField,
  DatagridConfigurable,
  FunctionField,
  List,
  ReferenceField,
  SingleFieldList,
  TextField,
  usePermissions,
  useSidebarState,
  useStore,
} from 'react-admin';
import { ProviderLocationFilter } from '../../modules/data-table/components/ProviderLocationFilter';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';

const DRAWER_WIDTH = 240;
const CLOSED_DRAWER_WIDTH = 55;
const BUFFER = 28;

function calcAge(dateString) {
  var birthday = +new Date(dateString);
  return ~~((Date.now() - birthday) / 31557600000);
}

const STORE_KEY = 'enrollmentQueue';

const Toolbar = () => {
  return (
    <div className="w-full">
      <EnrollmentListConfig />
      <DataTableToolbar
        showViewOptions={true}
        preferenceKey={STORE_KEY}
        actionButtons={[<EnrollmentExportButton />]}
        search={
          <MultiSearch
            searchFields={[
              { value: 'user.full_name', label: 'Name' },
              { value: 'id', label: 'ID' },
              { value: 'birthdate', label: 'DOB' },
              { value: 'user.phone_number', label: 'Phone Number' },
            ]}
          />
        }
      >
        <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
        <ProviderLocationFilter />
        <DataTableReferenceFilter
          resource="users"
          column="expected_onboarding_user_id[eq]"
          title="Enrollment User"
          optionText="full_name"
          customFilter={(name) => ({
            ...(name &&
              name.length > 1 && {
                q: { field: 'full_name', value: name },
              }),
          })}
        />

        <DataTableFilter
          column="primary_condition[in]"
          title="Primary Condition"
          options={[
            { label: 'Asthma', value: 'Asthma' },
            { label: 'COPD', value: 'COPD' },
            { label: 'Diabetes', value: 'Diabetes' },
            { label: 'General', value: 'general' },
            { label: 'Heart Failure', value: 'HeartFailure' },
            {
              label: 'Chronic Kidney Disease',
              value: 'chronic_kidney_disease',
            },
          ]}
        />
        <DataTableFilterSingle
          column="virtual_enrollment[eq]"
          title="Virtual Enrollment"
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
        />
      </DataTableToolbar>
    </div>
  );
};

const EnrollmentListBody = () => {
  const { permissions } = usePermissions();
  const [hideSandboxPatients] = useStore('preferences.hideSandboxPatients');
  const { config } = useEnrollmentQueue();

  const noDateSet = config.appointmentDate === null;

  return (
    <List
      resource="patients"
      storeKey={STORE_KEY}
      actions={<Toolbar />}
      exporter={false}
      filter={{
        ...(hideSandboxPatients && {
          'is_sandbox_user[eq]': false,
        }),
        'patient_status[in]': config.showEnrolledPatients
          ? ['pending_enrollment', 'active', 'declined']
          : ['pending_enrollment'],
        ...(config.appointmentDate && {
          'onboarding_appointment_datetime[gte]': config.appointmentDate.from,
          'onboarding_appointment_datetime[lte]': config.appointmentDate.to,
        }),
      }}
      queryOptions={{
        enabled: !noDateSet,
      }}
      empty={false}
      sort={{ field: 'onboarding_appointment_datetime', order: 'ASC' }}
      sx={{
        width: '100%',
        '& .RaList-main': {
          width: '100%',
        },
      }}
    >
      {noDateSet ? (
        <div className="flex flex-col items-center justify-center h-full gap-5 p-8 text-center">
          <p className="font-bold text-xl">No date range selected</p>
          <p className="text-gray-500 text-md">
            Please select a date range to view enrollment data.
          </p>
        </div>
      ) : (
        <DatagridConfigurable
          empty={
            <div className="flex flex-col items-center justify-center h-full gap-5 p-8 text-center">
              <p className="font-bold text-xl">No enrollment data found</p>
              <p className="text-gray-500 text-md">
                There are no patients in the enrollment queue for the selected
                date range.
              </p>
            </div>
          }
          rowClick="show"
          bulkActionButtons={false}
          preferenceKey={STORE_KEY}
          omit={['patient.profile.demographics.age', 'gender', 'email']}
          sx={{
            '& .RaDatagrid-root': {
              width: '100%',
            },
            '& .RaDatagrid-tableWrapper': {
              overflowX: 'scroll',
            },
            '& .RaDatagrid-table': {
              minWidth: '2000px',
            },
            '& .RaDatagrid-headerCell': {
              whiteSpace: 'nowrap',
            },
            '& .RaDatagrid-rowCell': {
              whiteSpace: 'nowrap',
            },
          }}
        >
          <BadgeField
            source="patient_status"
            label="Status"
            mapping={patientStatus}
          />
          <TextField
            source="user.full_name"
            label="Name"
            sortBy="user.last_name"
          />
          <DateTimeProviderTZField
            source="onboarding_appointment_datetime"
            label="Onboarding Appointment"
            showTime
            showTZ
          />
          <TextField source="user.email" label="Email" />
          <TextField source="gender" label="Sex at Birth" />

          <FunctionField
            source="birthdate"
            label="Age"
            render={(record) =>
              record.birthdate ? `${calcAge(record.birthdate)}Y` : ''
            }
          />

          <BadgeField
            source="primary_condition"
            mapping={primaryConditions}
            applyMappedClassName={false}
            humanize={false}
          />

          <LuxonDateField source="user.registered_on" label="Registered On" />
          {get(permissions, 'is_internal') === true ? (
            <ReferenceField
              label="Provider"
              source="user.tenant_id"
              reference="providers"
              link="show"
              emptyText="None"
              queryOptions={{
                refetchOnWindowFocus: false,
              }}
            >
              <TextField source="name" />
            </ReferenceField>
          ) : null}

          <ArrayField
            source="service_instances"
            sortable={false}
            label="Care Programs"
          >
            <SingleFieldList linkType={false}>
              <BadgeField
                source="type"
                humanize={false}
                capitalize={true}
                className="m-0.5"
              />
            </SingleFieldList>
          </ArrayField>

          <TextField source="eligible_devices" label="Eligible Devices" />
          <TextField source="insurances" label="Insurances" />
          <BadgeField
            source="insurance_coverage_type"
            label="Insurance Coverage Type"
          />

          <TextField
            source="expected_care_program"
            label="Expected Care Program"
          />
          <ReferenceField
            label="Enrollment User"
            source="expected_onboarding_user_id"
            reference="users"
            link="show"
            emptyText="None"
          >
            <TextField source="full_name" />
          </ReferenceField>
          <FunctionField
            source="virtual_enrollment"
            label="Is Virtual Enrollment"
            render={(record) => (record.virtual_enrollment ? 'Yes' : 'No')}
          />
          <ReferenceField
            label="Billing Provider"
            source="billing_provider_id"
            reference="users"
            link="show"
            emptyText="Not set"
          >
            <TextField source="full_name" />
          </ReferenceField>
          <EnrollmentEditButton label="Edit" />
        </DatagridConfigurable>
      )}
    </List>
  );
};

export const EnrollmentList: FC = () => {
  const [open] = useSidebarState();

  const listWidth = `calc(100vw - ${
    open ? DRAWER_WIDTH + BUFFER : CLOSED_DRAWER_WIDTH + BUFFER
  }px)`;

  return (
    <Box maxWidth={listWidth}>
      <AppTitle title="Enrollment Queue" />
      <EnrollmentQueueProvider>
        <EnrollmentListBody />
      </EnrollmentQueueProvider>
    </Box>
  );
};
