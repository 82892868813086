import AppTitle from '@/modules/layout/components/app-title';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Box, Grid, Stack, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Title,
  useCreateController,
  useNotify,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { CardSectionLayout } from '../../modules/testing/components/CardSectionLayout';

const testAccounts = [
  { id: 'copd@vironix.ai', name: 'copd@vironix.ai' },
  { id: 'copd_base@vironix.ai', name: 'copd_base@vironix.ai' },
  { id: 'asthma@vironix.ai', name: 'asthma@vironix.ai' },
  { id: 'asthma_base@vironix.ai', name: 'asthma_base@vironix.ai' },
  { id: 'chf_base@vironix.ai', name: 'chf_base@vironix.ai' },
  { id: 'chf@vironix.ai', name: 'chf@vironix.ai' },
];

export const Testing = (props) => {
  return (
    <Box sx={{ m: 4, marginBottom: 2 }}>
      <AppTitle title="Testing" />
      <Title title="Testing" />
      <Grid container spacing={1} direction="column">
        <Grid item>
          <CreateDemoPatient />
        </Grid>
        <Grid item>
          <ResetPatientAccount />
        </Grid>
        <Grid item>
          <ResetReviewAccounts />
        </Grid>
        <Grid item>
          <AddDeviceObservationToPatient />
        </Grid>
        <Grid item>
          <AddDeviceToPatient />
        </Grid>
        <Grid item>
          <AddDeviceObservationToPatientUpdated />
        </Grid>
      </Grid>
    </Box>
  );
};

const ResetPatientAccount = (props) => {
  const { save } = useCreateController({
    resource: 'testing/reset-test-patient',
    redirect: false,
  });

  const SubmitToolbar = () => (
    <Toolbar>
      <SaveButton label="Reset" icon={<SettingsBackupRestoreIcon />} />
    </Toolbar>
  );

  return (
    <CardSectionLayout title="Reset Patient Account">
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <Typography variant="body1">
          This will reset the test patient account to its default state.
        </Typography>
        <Typography variant="body1">Default password is: Easy1234</Typography>
        <SelectInput
          fullWidth
          required
          label="Patient account email"
          source="email"
          choices={testAccounts}
        />
        <Typography variant="body1" color="warning.main">
          Please wait 30 seconds after submitting this request before testing!
        </Typography>
      </SimpleForm>
    </CardSectionLayout>
  );
};

const ResetReviewAccounts = (props) => {
  const { save } = useCreateController({
    resource: 'testing/reset-app-store-patients',
    redirect: false,
  });

  const SubmitToolbar = () => (
    <Toolbar>
      <SaveButton
        alwaysEnable
        label="Reset"
        icon={<SettingsBackupRestoreIcon />}
      />
    </Toolbar>
  );

  return (
    <CardSectionLayout title="Reset Review Patient Accounts & Invite Codes">
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <Typography variant="body1">
          This will reset the review patient accounts to their default state
        </Typography>
      </SimpleForm>
    </CardSectionLayout>
  );
};

const DeviceObservationInput = (props) => {
  const deviceType = useWatch({ name: 'device_type' });

  return (
    <Stack>
      <SelectInput
        label="Device type"
        source="device_type"
        required
        choices={[
          { id: 'pulse_oximeter', name: 'Pulse Oximeter' },
          { id: 'blood_pressure', name: 'Blood Pressure' },
          { id: 'weight_scale', name: 'Weight scale' },
          { id: 'peak_flow_meter', name: 'Peak Flow Meter' },
          { id: 'glucometer', name: 'Glucometer' },
        ]}
      />
      {deviceType === 'pulse_oximeter' ? (
        <NumberInput required source="spo2" label="SpO2 (%)" />
      ) : null}
      {deviceType === 'pulse_oximeter' || deviceType === 'blood_pressure' ? (
        <NumberInput required source="pulse" label="Heart Rate (bpm)" />
      ) : null}
      {deviceType === 'blood_pressure' ? (
        <NumberInput required source="systolic" label="Systolic (mmHg)" />
      ) : null}
      {deviceType === 'blood_pressure' ? (
        <NumberInput required source="diastolic" label="Diastolic (mmHg)" />
      ) : null}
      {deviceType === 'weight_scale' ? (
        <NumberInput required source="weight" label="Weight" />
      ) : null}
      {deviceType === 'weight_scale' ? (
        <SelectInput
          required
          source="unit"
          label="Unit"
          choices={[
            { id: 'lbs', name: 'lbs' },
            { id: 'kg', name: 'kg' },
          ]}
        />
      ) : null}
      {deviceType === 'glucometer' ? (
        <NumberInput required source="blood_glucose" label="Blood Glucose" />
      ) : null}
      {deviceType === 'peak_flow_meter' ? (
        <NumberInput
          required
          source="peak_expiratory_flow_rate"
          label="Peak Flow"
        />
      ) : null}
    </Stack>
  );
};

const AddDeviceObservationToPatient = (props) => {
  const { save } = useCreateController({
    resource: 'testing/add-device-observation',
    redirect: false,
  });

  const SubmitToolbar = () => (
    <Toolbar>
      <SaveButton label="Add" />
    </Toolbar>
  );

  return (
    <CardSectionLayout title="Add Device Measurement">
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <Typography variant="body1">
          Adds a device measurement to the test patient account.
        </Typography>
        <Typography variant="body1">
          Use this to mock device measurements.
        </Typography>
        <SelectInput
          fullWidth
          required
          label="Patient account email"
          source="email"
          choices={testAccounts}
        />
        <DeviceObservationInput />
      </SimpleForm>
    </CardSectionLayout>
  );
};

const AddDeviceObservationToPatientUpdated = (props) => {
  const { save } = useCreateController({
    resource: 'testing/add-device-observation',
    redirect: false,
  });

  const SubmitToolbar = () => (
    <Toolbar>
      <SaveButton label="Add" />
    </Toolbar>
  );

  return (
    <CardSectionLayout title="Add Device Measurement">
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <Typography variant="body1">
          Adds a device measurement to the test patient account.
        </Typography>
        <Typography variant="body1">
          Use this to mock device measurements.
        </Typography>
        <NumberInput source="patient_id" />
        <DeviceObservationInput />
      </SimpleForm>
    </CardSectionLayout>
  );
};

const AddDeviceToPatient = (props) => {
  const { save } = useCreateController({
    resource: 'testing/add-device',
    redirect: false,
  });

  const SubmitToolbar = () => (
    <Toolbar>
      <SaveButton label="Add" />
    </Toolbar>
  );

  return (
    <CardSectionLayout title="Add Device">
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <Typography variant="body1">
          Adds a test device to a test patient account.
        </Typography>
        <SelectInput
          fullWidth
          required
          label="Patient account email"
          source="email"
          choices={testAccounts}
        />
        <SelectInput
          required
          label="Device type"
          source="device_type"
          choices={[
            { id: 'pulse_oximeter', name: 'Pulse Oximeter' },
            { id: 'blood_pressure', name: 'Blood Pressure' },
          ]}
        />
        <TextInput source="external_id" label="IMEI" required={false} />
      </SimpleForm>
    </CardSectionLayout>
  );
};

const CreateDemoPatient = (props) => {
  const [accountID, setAccountID] = useState(null);
  const { save } = useCreateController({
    resource: 'testing/create-demo-patient',
    redirect: false,
    mutationOptions: {
      onSuccess: (data) => {
        notify(`Patient account created. ID: ${data.id}`);
        setAccountID(data.id);
      },
    },
  });

  const notify = useNotify();

  const SubmitToolbar = () => (
    <Toolbar>
      <Stack direction="row" spacing={2}>
        <SaveButton label="Add" />
        {accountID && (
          <Link to={`/patients/${accountID}/show`}>
            View recently created demo patient
          </Link>
        )}
      </Stack>
    </Toolbar>
  );

  return (
    <CardSectionLayout title="Create Demo Patient">
      <SimpleForm
        onSubmit={(data) => save(data)}
        record={{}}
        toolbar={<SubmitToolbar />}
      >
        <Typography variant="body1">
          Creates a patient account for demo purposes. COPD supported for now.
        </Typography>
        <ReferenceInput
          label="Provider"
          reference="providers"
          source="tenant_id"
          alwaysOn
        >
          <SelectInput optionText="name" label="Provider" helperText={false} />
        </ReferenceInput>

        <ReferenceInput
          reference="users"
          source="practitioner_id"
          alwaysOn
          filter={{
            role: `in:superadmin,admin,clinical_staff,staff_member`,
          }}
        >
          <SelectInput
            optionText="full_name"
            label="Practitioner for monitoring logs"
            helperText={false}
          />
        </ReferenceInput>
      </SimpleForm>
    </CardSectionLayout>
  );
};
