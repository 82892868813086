import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import { get, set } from 'lodash';
import React, { FC } from 'react';
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  List,
  ReferenceInput,
  TextInput,
  maxLength,
  minLength,
  required,
  useGetIdentity,
  usePermissions,
  useStore,
  FormDataConsumer,
  SelectInput,
  SelectArrayInput,
} from 'react-admin';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { PhoneNumberInput } from '@/modules/ra-ui/components/PhoneNumberInput';
import AppTitle from '@/modules/layout/components/app-title';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import ProviderField from '@/modules/ra-ui/components/ProviderField';
import { filterTenantName } from '@/utils/filterTenantName';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';
import { RoleRadioSelect } from '@/modules/ra-ui/components/RoleRadioSelect';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';
import { serviceInstanceTypesChoices } from '@/modules/patients/constants/serviceInstanceTypesChoices';
import { clinicianTypes } from '@/modules/users/constants/clinicanTypes';
import { NameAvatarField } from '@/modules/users/components/NameAvatarField';

const Toolbar = ({ selectedTab, setSelectedTab }) => (
  <div className="w-full">
    <TabbedWrapper
      options={[
        {
          title: 'Active',
          value: 'active',
        },
        {
          title: 'Inactive',
          value: 'inactive',
        },
        {
          title: 'All',
          value: '',
        },
      ]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    >
      <DataTableToolbar
        showViewOptions={false}
        createButton={<UserCreate />}
        search={
          <MultiSearch
            searchFields={[
              { value: 'full_name', label: 'Name' },
              { value: 'id', label: 'ID' },
            ]}
          />
        }
      >
        <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
      </DataTableToolbar>
    </TabbedWrapper>
  </div>
);

const UserCreate = () => {
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();

  const transform = (data) => {
    if (
      get(data, 'phone_number') === '+1' ||
      get(data, 'phone_number') === ''
    ) {
      set(data, 'phone_number', null);
    }

    set(data, 'email_invite', true);

    if (!!!get(permissions, 'is_internal')) {
      set(data, 'tenant_id', identity.tenant_id);
    }
    return data;
  };

  return (
    <CreateInDialogButton
      title="Create user"
      transform={transform}
      redirect="list"
      label="User"
    >
      <div className="flex flex-col gap-1">
        <div className="flex flex-row gap-1">
          <TextInput source="first_name" validate={[required()]} />
          <TextInput source="middle_name" />
          <TextInput source="last_name" validate={[required()]} />
        </div>
        <div className="flex flex-row gap-1">
          <TextInput source="email" type="email" validate={[required()]} />
          <PhoneNumberInput label="Phone Number" source="phone_number" />
        </div>
        {get(permissions, 'is_internal') ? (
          <ReferenceInput source="tenant_id" reference="providers">
            <AutocompleteInput
              label="Provider"
              optionText="name"
              validate={[required()]}
              filterToQuery={filterTenantName}
            />
          </ReferenceInput>
        ) : null}
        <div className="flex flex-row gap-2 items-center">
          <TextInput
            source="npi_number"
            label="NPI Number"
            helperText="10-digit NPI Number"
            validate={[
              minLength(10, 'NPI number must be 10 digits'),
              maxLength(10, 'NPI number must be 10 digits'),
            ]}
          />
          <TextInput source="credentials" helperText="MD, RN, PhD" />
          <BooleanInput source="is_provider" label="Is clinician" />
        </div>
        <FormDataConsumer>
          {({ formData }) =>
            formData.is_provider ? (
              <>
                <SelectInput
                  validate={required()}
                  source="clinician_type"
                  choices={clinicianTypes}
                  helperText={false}
                />
                <SelectArrayInput
                  validate={required()}
                  source="enabled_care_program_types"
                  choices={serviceInstanceTypesChoices.filter(
                    (c) => c.enabled !== false,
                  )}
                  helperText={false}
                />
              </>
            ) : null
          }
        </FormDataConsumer>
        <RoleRadioSelect internal={false} />

        <p className="text-sm text-gray-500">
          New members will receive an email with instructions on setting up
          their password and logging in.
        </p>
      </div>
    </CreateInDialogButton>
  );
};
export const UserList: FC = (props) => {
  const [hideSandboxPatients] = useStore('preferences.hideSandboxPatients');
  const [selectedTab, setSelectedTab] = useStore(
    'users.listParams.status',
    'active',
  );

  return (
    <>
      <AppTitle title="Users" />
      <List
        {...props}
        filter={{
          'role[ne]': `patient`,
          'is_internal[ne]': true,
          ...(hideSandboxPatients && { 'is_sandbox_user[ne]': true }),
          ...(selectedTab &&
            selectedTab === 'active' && { 'is_active[eq]': true }),
          ...(selectedTab &&
            selectedTab === 'inactive' && { 'is_active[ne]': true }),
        }}
        exporter={false}
        empty={false}
        actions={
          <Toolbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        }
      >
        <Datagrid rowClick="edit">
          <NameAvatarField sortBy="last_name" label="Name" />
          <LuxonDateField source="registered_on" label="Registered On" />
          <LuxonDateField source="last_login" label="Last Login" />
          <BadgeField source="role" />
          <BooleanField source="is_active" label="Active" />
          <ProviderField
            label="Provider"
            source="tenant_id"
            sortBy="tenant.name"
          />
        </Datagrid>
      </List>
    </>
  );
};
