import IncompatibleBillingWarning from '@/modules/monitoring-activity/components/IncompatibleBillingWarning';
import { monitoringActivityTypes } from '@/modules/monitoring-activity/constants/monitoringActivityTypes';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { Button } from '@/modules/ui/components/button';
import { Card, CardContent, CardHeader } from '@/modules/ui/components/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/modules/ui/components/dropdown-menu';
import DateTimeProviderTZInput from '@/modules/ui/inputs/date-time-provider-tz-input';
import { SelectInput } from '@/modules/ui/inputs/select-input';
import { SwitchInput } from '@/modules/ui/inputs/switch-input';
import { TextAreaInput } from '@/modules/ui/inputs/text-area-input';
import { filter, find, get, isEmpty, map } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import {
  FormDataConsumer,
  required,
  useCreate,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { serviceInstanceTypes } from '../../patients/constants/serviceInstanceTypes';
import presetNotes from '../constants/presetNotes';
import { ElapsedTime } from './ElapsedTime';
import StoredForm from './StoredForm';
const monitoringActivityTypeChoices = map(monitoringActivityTypes, (v, k) => ({
  name: v,
  id: k,
}));

export const MonitoringActivityCreateAside = ({ handleCancelCreate }) => {
  const patientRecord = useRecordContext();
  const { identity } = useGetIdentity();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate();
  const tenant_id = get(patientRecord, 'user.tenant_id');

  const serviceInstanceChoices = useMemo(
    () =>
      get(patientRecord, 'service_instances', [])
        .filter((serviceInstance) => serviceInstance.is_active)
        .map((i) => ({
          id: i.id.toString(),
          name: serviceInstanceTypes[i.type],
        })),
    [patientRecord],
  );

  if (!patientRecord) return null;

  const transform = (data) => ({
    ...data,
    patient_id: patientRecord.id,
    practitioner_id: identity.id,
    reviewed_on: data.reviewed_on
      ? data.reviewed_on
      : `${DateTime.utc().toISO()}`,
    type: data.type || 'note',
  });

  const onSubmit = (data) => {
    create(
      'monitoring-activities',
      { data: transform(data) },
      {
        onSuccess: (data) => {
          notify('Monitoring activity created');
          refresh();
          handleCancelCreate();
          sessionStorage.removeItem(
            `vironix.monitoring-activity_${patientRecord.id}`,
          );
        },
        onError: (error) => {
          notify(
            typeof error === 'string'
              ? error
              : get(error, 'message') || 'ra.notification.http_error',
            { type: 'warning' },
          );
        },
      },
    );
  };

  return (
    <StoredForm
      formKey={`monitoring-activity_${patientRecord.id}`}
      onSubmit={onSubmit}
      resource="monitoring-activities"
      record={{}}
    >
      <Card>
        <CardHeader className="pt-3 pb-2">
          <p className="text-lg font-semibold">Add Activity</p>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-1 items-stretch">
            {!isEmpty(serviceInstanceChoices) ? (
              <SelectInput
                label="Care Program"
                source="service_instance_id"
                choices={serviceInstanceChoices}
                fullWidth
              />
            ) : null}
            <FormDataConsumer>
              {({ formData }) => {
                const matchedServiceInstance = find(
                  get(patientRecord, 'service_instances', []),
                  {
                    id: parseInt(formData.service_instance_id, 10),
                  },
                );

                if (!matchedServiceInstance) return null;
                const choices = filter(monitoringActivityTypeChoices, (v) =>
                  v.id.startsWith(matchedServiceInstance.type),
                );

                choices.unshift({ name: 'Note', id: 'note' });

                return (
                  <>
                    <SelectInput
                      source="type"
                      choices={choices}
                      fullWidth
                      helperText="Specify which service code this work belongs to."
                      validate={[required()]}
                    />
                    <IncompatibleBillingWarning
                      patient_id={patientRecord?.id}
                      className="my-2"
                    />
                  </>
                );
              }}
            </FormDataConsumer>
            <ElapsedTime />
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <DateTimeProviderTZInput
                    source="reviewed_on"
                    label="Date"
                    tenant_id={tenant_id}
                    defaultValue={DateTime.utc().startOf('second').toISO({
                      includeOffset: false,
                      suppressMilliseconds: true,
                    })}
                    dateTimeFormat="DD ttt"
                  />
                );
              }}
            </FormDataConsumer>
            <div className="flex flex-row gap-2 py-2 flex-wrap">
              <SwitchInput
                source="data_reviewed"
                defaultValue={false}
                helperText={false}
                label="Data reviewed"
              />
              <SwitchInput
                source="patient_contacted"
                defaultValue={false}
                helperText={false}
                label="Patient contacted"
              />
            </div>
            <FormDataConsumer>
              {({ formData }) => (
                <>
                  <TextAreaInput
                    label="Notes"
                    source="description"
                    validate={required('Notes are required.')}
                    activityType={formData.type}
                    minRows={4}
                  />
                  <PresetNotesMenu activityType={formData.type} />
                </>
              )}
            </FormDataConsumer>
          </div>
        </CardContent>
      </Card>
      <div className="flex flex-col justify-start gap-2 items-end mt-4">
        <div className="flex flex-row gap-2">
          <SaveButton
            variant="default"
            type="submit"
            size="sm"
            label="Save"
            className="w-32"
            alwaysEnable={true}
          />
          <ClearButton />
        </div>
        <Button variant="link" onClick={handleCancelCreate} type="button">
          Cancel
        </Button>
      </div>
    </StoredForm>
  );
};

const ClearButton = () => {
  const { reset } = useFormContext();
  const patientRecord = useRecordContext();

  if (!patientRecord) return null;
  const handleClearForm = () => {
    reset(
      {
        reviewed_on: DateTime.utc().startOf('second').toISO({
          includeOffset: false,
          suppressMilliseconds: true,
        }),
      },
      {
        keepDefaultValues: false,
        keepValues: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      },
    );
    sessionStorage.removeItem(
      `vironix.monitoring-activity_${patientRecord?.id}`,
    );
  };

  return (
    <Button variant="outline" onClick={handleClearForm} type="button">
      Clear
    </Button>
  );
};

const PresetNotesMenu = ({ activityType, source = 'description' }) => {
  const { setValue } = useFormContext();

  const handleClickPresetMessage = (event) => {
    const message = event.target.dataset.message;
    setValue(source, message);
  };

  if (isEmpty(activityType) || isEmpty(presetNotes[activityType])) return null;

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="w-fit">
          Preset notes
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {activityType
          ? get(presetNotes, activityType, []).map((message, key) => (
              <DropdownMenuItem
                onSelect={handleClickPresetMessage}
                data-message={message.body}
                key={key}
              >
                {message.name}
              </DropdownMenuItem>
            ))
          : null}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
