import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/modules/ui/components/card';
import { LoadingSpinner } from '@/modules/ui/components/loading-spinner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/modules/ui/components/select';
import { get, last } from 'lodash';
import { DateTime } from 'luxon';
import { useGetOne, usePermissions } from 'ra-core';
import React from 'react';
import {
  Area,
  AreaChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { DailyEnrollmentPlot } from './DailyEnrollmentPlot';

const CustomTooltip = (props) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-1 border-2 rounded-md">
        <p className="label">{`Date: ${DateTime.fromISO(label).toFormat(
          'LLL d, yyyy',
        )}`}</p>
        <p className="intro">{`${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export const EnrollmentMetricPlot = ({
  providerId,
}: {
  providerId: string | undefined;
}) => {
  const [range, setRange] = React.useState('6');
  const { data, isLoading } = useGetOne(
    'metrics',
    {
      id: 'enrollment-plot',
      meta: { tenant_id: providerId, months: parseInt(range) },
    },
    {
      queryKey: ['enrollment-plot', providerId],
      enabled: providerId !== '',
    },
  );

  const { permissions } = usePermissions();
  const formatXAxis = (tickItem) => {
    return DateTime.fromISO(tickItem).toFormat('LLL d');
  };

  if (isLoading) {
    return (
      <div className="h-64">
        <LoadingSpinner />
      </div>
    );
  }

  if (!data?.patient_onboarding_count.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-lg font-bold">Patient Enrollment</h1>
        <Select value={range} onValueChange={(value) => setRange(value)}>
          <SelectTrigger className="w-[120px] h-[30px]">
            <SelectValue placeholder="Select time range" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="3">3 months</SelectItem>
            <SelectItem value="6">6 months</SelectItem>
            <SelectItem value="12">1 year</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="flex flex-row gap-2 flex-wrap w-full">
        <Card className="max-w-lg w-full">
          <CardContent>
            <CardHeader>
              <CardTitle className="text-sm font-medium mr-4">
                Total enrolled
              </CardTitle>
              <div className="text-2xl font-bold">
                {get(last(data?.patient_onboarding_count), 'count', 'N/A')}
              </div>
            </CardHeader>
            <div className="h-64 ">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={300}
                  data={data?.patient_onboarding_count}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="enrolled_date" tickFormatter={formatXAxis} />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    type="monotone"
                    dataKey="count"
                    stroke="#8884d8"
                    strokeWidth={3}
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#8884d8"
                    strokeWidth={3}
                    dot={false}
                    activeDot={{ r: 8 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>
        {get(permissions, 'is_internal') === true ? (
          <DailyEnrollmentPlot
            providerId={providerId}
            months={parseInt(range)}
          />
        ) : null}
      </div>
    </div>
  );
};
