import ProviderField from '@/modules/ra-ui/components/ProviderField';
import AppTitle from '@/modules/layout/components/app-title';
import { DataTableFilter } from '@/modules/data-table/components/data-table-filter';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import inflection from 'inflection';
import React, { FC } from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import DeviceExternalIdField from '@/modules/devices/components/DeviceExternalIdField';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { DeviceCreateButton } from '../../modules/devices/components/DeviceCreateButton';
import { DeviceEmpty } from '../../modules/devices/components/DeviceEmpty';
import { deviceStatusTypes } from '@/modules/devices/constants/deviceStatusTypes';
import { deviceTypes } from '@/modules/devices/constants/deviceTypes';
import { BulkEditDevices } from '../../modules/devices/components/BulkEditDevices';
import { BulkMarkDeviceReceivedButton } from '@/modules/devices/components/BulkMarkDeviceReceivedButton';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';

const DeviceBulkActionButtons = () => (
  <>
    <BulkEditDevices />
    <BulkMarkDeviceReceivedButton />
  </>
);

const Toolbar = () => (
  <DataTableToolbar
    showViewOptions={false}
    createButton={<DeviceCreateButton variant="default" />}
    search={
      <MultiSearch
        searchFields={[
          { value: 'external_id', label: 'IMEI' },
          { value: 'user.full_name', label: 'Patient Name' },
          { value: 'id', label: 'ID' },
        ]}
      />
    }
  >
    <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
    <DataTableFilter
      column="device_type[in]"
      title="Device Type"
      options={deviceTypes.map((type) => ({
        label: type.name,
        value: type.id,
      }))}
    />
    <DataTableFilter
      column="status[in]"
      title="Device Status"
      options={deviceStatusTypes.map((type) => ({
        label: type.name,
        value: type.id,
      }))}
    />
  </DataTableToolbar>
);

export const DeviceList: FC = (props) => {
  return (
    <>
      <AppTitle title="Devices" />
      <List
        {...props}
        exporter={false}
        actions={<Toolbar />}
        empty={<DeviceEmpty />}
        sort={{ field: 'added_on', order: 'DESC' }}
      >
        <Datagrid
          rowClick="show"
          bulkActionButtons={<DeviceBulkActionButtons />}
          isRowSelectable={(record) =>
            record.patient_id === null && record.status !== 'decommissioned'
          }
        >
          <FunctionField
            source="device_manufacturer"
            render={(record) =>
              inflection.titleize(
                record.device_manufacturer ? record.device_manufacturer : '',
              )
            }
          />
          <DeviceExternalIdField source="external_id" label="IMEI" crop={20} />
          <BadgeField source="device_type" mapping={deviceTypes} />
          <ProviderField
            label="Provider"
            source="tenant_id"
            sortBy="tenant.name"
          />
          <ReferenceField
            source="location_id"
            reference="locations"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Assigned patient"
            source="patient_id"
            reference="patients"
            link="show"
            sortBy="user.last_name"
          >
            <TextField source="user.full_name" />
          </ReferenceField>
          <LuxonDateField source="added_on" />
          <LuxonDateField source="assigned_on" />
          <BadgeField source="status" />
        </Datagrid>
      </List>
    </>
  );
};
