import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import AppTitle from '@/modules/layout/components/app-title';
import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { PhoneNumberInput } from '@/modules/ra-ui/components/PhoneNumberInput';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import TabbedWrapper from '@/modules/ra-ui/components/tabbed-wrapper';
import { get, set } from 'lodash';
import React, { FC } from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  List,
  TextInput,
  maxLength,
  minLength,
  required,
  useStore,
} from 'react-admin';
import { RoleRadioSelect } from '../../modules/ra-ui/components/RoleRadioSelect';
import { NameAvatarField } from '@/modules/users/components/NameAvatarField';
import { MultiSearch } from '@/modules/data-table/components/data-table-multi-search';

const Toolbar = ({ selectedTab, setSelectedTab }) => (
  <div className="w-full">
    <TabbedWrapper
      options={[
        {
          title: 'Active',
          value: 'active',
        },
        {
          title: 'Inactive',
          value: 'inactive',
        },
        {
          title: 'All',
          value: '',
        },
      ]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    >
      <DataTableToolbar
        showViewOptions={false}
        createButton={<UserCreate />}
        search={
          <MultiSearch
            searchFields={[
              { value: 'full_name', label: 'Name' },
              { value: 'id', label: 'ID' },
            ]}
          />
        }
      ></DataTableToolbar>
    </TabbedWrapper>
  </div>
);

const UserCreate = () => {
  const transform = (data) => {
    if (
      get(data, 'phone_number') === '+1' ||
      get(data, 'phone_number') === ''
    ) {
      set(data, 'phone_number', null);
    }

    return {
      ...data,
      is_internal: true,
      email_invite: true,
    };
  };

  return (
    <CreateInDialogButton
      title="Create internal user"
      transform={transform}
      redirect="internal-users"
      label="User"
    >
      <div className="flex-1">
        <div className="flex flex-row gap-1">
          <TextInput source="first_name" validate={[required()]} />
          <TextInput source="middle_name" />
          <TextInput source="last_name" validate={[required()]} />
        </div>
        <div className="flex flex-row gap-1">
          <TextInput source="email" type="email" validate={[required()]} />
          <PhoneNumberInput label="Phone Number" source="phone_number" />
        </div>
        <div className="flex flex-row gap-1">
          <TextInput
            source="npi_number"
            label="NPI Number"
            helperText="10-digit NPI Number"
            validate={[
              minLength(10, 'NPI number must be 10 digits'),
              maxLength(10, 'NPI number must be 10 digits'),
            ]}
          />
          <TextInput source="credentials" helperText="MD, RN, PhD" />
          <BooleanInput source="is_provider" label="Is clinician" />
        </div>
        <RoleRadioSelect internal={true} />
      </div>
    </CreateInDialogButton>
  );
};

export const InternalUserList: FC = () => {
  const [hideSandboxPatients] = useStore('preferences.hideSandboxPatients');
  const [selectedTab, setSelectedTab] = useStore(
    'internal-users.listParams.status',
    'active',
  );

  return (
    <>
      <AppTitle title="Internal Users" />
      <List
        resource="users"
        filter={{
          'is_internal[eq]': true,
          ...(hideSandboxPatients && { 'is_sandbox_user[ne]': true }),
          ...(selectedTab &&
            selectedTab === 'active' && { 'is_active[eq]': true }),
          ...(selectedTab &&
            selectedTab === 'inactive' && { 'is_active[ne]': true }),
        }}
        empty={false}
        exporter={false}
        actions={
          <Toolbar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        }
        storeKey="internal-users.listParams"
      >
        <Datagrid rowClick={(id, resource, record) => `/internal-users/${id}/`}>
          <NameAvatarField sortBy="last_name" label="Name" />
          <LuxonDateField source="registered_on" label="Registered On" />
          <LuxonDateField source="last_login" label="Last Login" />
          <BooleanField source="mfa_enabled" label="2FA" />
          <BooleanField source="is_active" label="Active" />
          <BadgeField source="role" label="Role" />
        </Datagrid>
      </List>
    </>
  );
};
