/* eslint-disable no-prototype-builtins */
import { get, set, sample } from 'lodash';
import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  CreateBase,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  useGetIdentity,
  useNotify,
  usePermissions,
  useRedirect,
  useStore,
} from 'react-admin';
import {
  PatientAsthmaSection,
  PatientCHFSection,
  PatientCOPDSection,
  PatientChronicKidneyDiseaseSection,
  PatientDemographics,
  PatientDiabetesSection,
  PatientGeneralDetails,
  PatientMedicalHistory,
  PatientRPMInfo,
  PatientScreenedSection,
} from './PatientCreateEdit';

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from '@/modules/ui/components/card';

import AppTitle from '@/modules/layout/components/app-title';
import { ethnicity } from '@/modules/patients/constants/ethnicity';
import { race } from '@/modules/patients/constants/race';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { Button } from '@/modules/ui/components/button';
import { cn } from '@/modules/ui/utils/cn';
import { filterTenantName } from '@/utils/filterTenantName';
import { processAPIError } from '@/utils/processAPIError';
import { FileSignature, Plus, ScanSearch } from 'lucide-react';
import { Form, useInput } from 'ra-core';
import { useFormContext } from 'react-hook-form';
import { sanitizeEmptyValuesCustom } from './sanitizeEmptyValuesCustom';
import { DateTime } from 'luxon';
import { useOrganization } from '@/modules/organizations/components/OrganizationProvider';

const filterProviderName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

export function PatientCreate() {
  const notify = useNotify();
  const redirect = useRedirect();
  const [, setOtp] = useStore('otp', '');
  const { identity } = useGetIdentity();

  const transform = (data) => {
    set(data, 'role', 'patient');

    if (get(data, 'tenant_id') === '') {
      delete data.tenant_id;
    }

    if (get(data, 'enrollment_type') === 'consented') {
      set(data, 'patient.enrolled_by_id', identity.id);
      set(data, 'patient.patient_status', 'active');
      set(data, 'enrolled_on', DateTime.local().toUTC().toISO());
    } else if (get(data, 'enrollment_type') === 'screening') {
      set(data, 'patient.patient_status', 'pending_enrollment');
    }

    const data_2 = sanitizeEmptyValuesCustom(data);
    return data_2;
  };

  const onSuccess = (data) => {
    setOtp(data.first_otp);
    notify(`Created patient ${data.user.full_name}`);
    redirect(`/patients/${data.id}/show`);
  };

  const onError = (error) => {
    if (get(error, 'message', '').includes('Key')) {
      notify(`Patient already exists.`, { type: 'warning' });
    } else {
      notify(
        processAPIError(error) || error.message || 'ra.notification.http_error',
        { type: 'warning' },
      );
    }
  };

  return (
    <CreateBase
      resource="patients"
      transform={transform}
      mutationOptions={{ onSuccess, onError }}
    >
      <div className="flex flex-col gap-1 w-full my-0">
        <AppTitle title={`Add patient`} />
        <p className="text-base ">
          Add patient to your instance for VCM Services
        </p>
      </div>
      <Form className="my-2">
        <Card className="mb-4">
          <CardContent>
            <SelectEnrollmentType source="enrollment_type" />

            <FormDataConsumer>
              {({ formData }) => {
                if (formData.enrollment_type === 'screening')
                  return <EnrollmentScreenedForm />;
                else if (formData.enrollment_type === 'consented')
                  return <EnrollmentConsentedForm />;
              }}
            </FormDataConsumer>
          </CardContent>
        </Card>
        <div className="flex flex-row gap-2 justify-end">
          <MockPatientButton />
          <SaveButton variant="default" label="Add" icon={<Plus />} />
        </div>
      </Form>
    </CreateBase>
  );
}

const SelectEnrollmentType = ({
  defaultValue,
  format,
  parse,
  resource,
  source,
  validate,
  onBlur,
  onChange,
  ...rest
}: any) => {
  const choices = [
    {
      id: 'consented',
      name: 'Consented',
      icon: <FileSignature className="h-16 w-16" />,
      description:
        'Add new patient that has consented for virtual care services.',
    },
    {
      id: 'screening',
      name: 'Screening',
      icon: <ScanSearch className="h-16 w-16" />,
      description:
        'Initiate a new patient which has been screened for virtual care services.',
    },
  ];

  const { field } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  return (
    <div className="flex flex-col gap-1 my-2">
      <p className="text-md  font-medium">Select enrollment type</p>
      <div className="flex flex-row gap-2">
        {choices.map((choice) => (
          <Card
            className={cn(
              'flex flex-col items-center justify-center  w-[300px] cursor-pointer hover:shadow-lg',
              field.value === choice.id ? 'bg-gray-100 border-gray-500' : '',
            )}
            onClick={() => {
              field.onChange(choice.id);
            }}
          >
            <div className="p-2">
              <div className="flex flex-row gap-2 justify-between ">
                <div className="p-2">
                  <CardTitle>{choice.name}</CardTitle>
                  <CardDescription>{choice.description}</CardDescription>
                </div>
                {choice.icon}
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
const EnrollmentScreenedForm = () => {
  const { permissions } = usePermissions();
  const { organization } = useOrganization();
  const sourceRoot = 'patient.';

  return (
    <>
      <PatientGeneralDetails sourceRoot={sourceRoot} />
      <PatientRPMInfo sourceRoot={sourceRoot}>
        {get(permissions, 'is_internal') ? (
          <ReferenceInput source={'tenant_id'} reference="providers">
            <AutocompleteInput
              label="Provider"
              optionText="name"
              validate={required()}
              helperText={false}
              fullWidth
              filterToQuery={filterTenantName}
            />
          </ReferenceInput>
        ) : null}
        <FormDataConsumer>
          {({ formData }) =>
            organization?.id || formData.tenant_id ? (
              <ReferenceInput
                source="billing_provider_id"
                reference="users"
                filter={{
                  'role[ne]': 'patient',
                  'npi_number[ne]': null,
                  'is_provider[eq]': true,
                  'tenant_id[eq]': organization?.id || formData.tenant_id,
                }}
              >
                <AutocompleteInput
                  label="Billing Provider"
                  optionText="full_name"
                  parse={(v) => (v === '' ? null : v)}
                  helperText={false}
                  filterToQuery={filterProviderName}
                  fullWidth
                  validate={required()}
                />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>
      </PatientRPMInfo>
      <PatientScreenedSection
        sourceRoot={sourceRoot}
        tenant_id={organization?.id}
      />
    </>
  );
};

const EnrollmentConsentedForm = () => {
  const { permissions } = usePermissions();
  const sourceRoot = 'patient.';

  return (
    <>
      <PatientGeneralDetails sourceRoot={sourceRoot} />
      <PatientRPMInfo sourceRoot={sourceRoot}>
        {get(permissions, 'is_internal') ? (
          <ReferenceInput source={'tenant_id'} reference="providers">
            <AutocompleteInput
              label="Provider"
              optionText="name"
              validate={required()}
              helperText={false}
              sx={{
                width: '300px',
              }}
              filterToQuery={filterTenantName}
            />
          </ReferenceInput>
        ) : null}

        <FormDataConsumer>
          {({ formData }) => {
            if (formData.email)
              return (
                <RadioButtonGroupInput
                  source="create_one_time_password"
                  label="Would you like to generate a one time password for this user?"
                  validate={required(
                    'Please select whether you would like to create an invite code for the patient',
                  )}
                  choices={[
                    { id: true, name: 'Yes' },
                    { id: false, name: 'No' },
                  ]}
                  helperText={false}
                />
              );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.email && formData.create_one_time_password === 'true')
              return (
                <RadioButtonGroupInput
                  source="email_invite"
                  label="Would you like to send this patient an invite email to set up their account?"
                  required
                  choices={[
                    { id: true, name: 'Yes' },
                    { id: false, name: 'No' },
                  ]}
                  helperText={false}
                />
              );
            else return null;
          }}
        </FormDataConsumer>
      </PatientRPMInfo>
      <div>
        <Alert>
          <FileSignature className="h-4 w-4" />

          <AlertTitle>Patient consent</AlertTitle>
          <AlertDescription>
            <BooleanInput
              source="is_patient_consented"
              label="Yes, this patient has confirmed to be enrolled in the VCM program."
              validate={[
                required(),
                (value) =>
                  value === false
                    ? 'Please confirm that the patient has consented to be enrolled'
                    : null,
              ]}
            />
          </AlertDescription>
        </Alert>
      </div>
      <PatientDemographics sourceRoot={sourceRoot} />
      <PatientMedicalHistory sourceRoot={sourceRoot} />
      <PatientCHFSection sourceRoot={sourceRoot} />
      <PatientCOPDSection sourceRoot={sourceRoot} />
      <PatientAsthmaSection sourceRoot={sourceRoot} />
      <PatientDiabetesSection sourceRoot={sourceRoot} />
      <PatientChronicKidneyDiseaseSection sourceRoot={sourceRoot} />
    </>
  );
};

const MockPatientButton = () => {
  const env = window.config.env;
  const { reset, getValues } = useFormContext();

  const generateMockPatient = () => {
    let mockPatient = getValues();

    mockPatient = {
      ...mockPatient,
      first_name: 'James',
      last_name: 'Smith',
      email: `${randomString(10)}@gmail.com`,
      create_one_time_password: false,
      address: {
        address_line1: '1234 Main St',
        city: 'San Francisco',
        state: 'CA',
        zip_code: '94105',
        country: 'United States',
      },
      is_patient_consented: true,
    };

    set(
      mockPatient,
      'patient.patient.profile.demographics.race',
      sample(race).id,
    );
    set(
      mockPatient,
      'patient.patient.profile.demographics.ethnicity',
      sample(ethnicity).id,
    );

    set(mockPatient, 'patient.birthdate', randomDate());
    set(
      mockPatient,
      'patient.primary_condition',
      sample(['COPD', 'HeartFailure', 'general']),
    );
    set(mockPatient, 'patient.gender', sample(['Male', 'Female']));

    reset(mockPatient);
  };

  if (env === 'production') return null;

  return (
    <Button
      onClick={generateMockPatient}
      variant="default"
      className="bg-orange-400 hover:bg-orange-500"
      type="button"
    >
      Generate Mock Patient
    </Button>
  );
};

function randomString(string_length) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  var randomstring = '';
  for (var i = 0; i < string_length; i++) {
    var rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars[rnum];
  }
  return randomstring;
}

// random date between 20 years and 40 years ago, only date, no time
function randomDate() {
  var start = new Date();
  start.setFullYear(start.getFullYear() - 40);
  var end = new Date();
  end.setFullYear(end.getFullYear() - 20);
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime()),
  )
    .toISOString()
    .split('T')[0];
}
