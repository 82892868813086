import React, { useState, useMemo, useEffect } from 'react';
import { useListContext } from 'react-admin';
import { useForm, FormProvider } from 'react-hook-form';
import { get } from 'lodash';
import { Input } from '@/modules/ui/components/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/modules/ui/components/select';
import { Separator } from '@/modules/ui/components/separator';
import parsePhoneNumber from 'libphonenumber-js';
import { Button } from '@/modules/ui/components/button';
import { SearchIcon } from 'lucide-react';

interface SearchField {
  value: string;
  label: string;
}

interface MultiSearchProps {
  defaultSource?: string;
  placeholder?: string;
  searchFields?: SearchField[];
}

export const MultiSearch: React.FC<MultiSearchProps> = ({
  placeholder = 'Search ...',
  searchFields,
}) => {
  const { filterValues, setFilters } = useListContext();
  const [notification, setNotification] = useState('');
  const [selectedField, setSelectedField] = useState(searchFields[0].value);

  // Default search fields if not provided
  const defaultSearchFields: SearchField[] = [
    { value: 'user.full_name', label: 'Name' },
    { value: 'id', label: 'ID' },
  ];

  // Use provided searchFields or default if not provided
  const actualSearchFields = searchFields || defaultSearchFields;

  const updateFilters = (searchValue: string) => {
    if (searchValue.trim().length === 0) {
      const { q, ...filters } = filterValues;
      setFilters(filters, null, false);
    } else {
      setFilters(
        {
          ...filterValues,
          q: {
            field: selectedField,
            value: searchValue,
          },
        },
        null,
      );
    }
  };

  const initialValues = useMemo(
    () => ({
      q:
        get(filterValues, 'q.field') === selectedField
          ? get(filterValues, 'q.value', '')
          : '',
    }),
    [filterValues, selectedField],
  );

  const form = useForm({ defaultValues: initialValues });

  useEffect(() => {
    // Check if search is cleared externally
    if (!get(filterValues, 'q')) {
      form.reset({ q: '' });
    }
  }, [filterValues, form]);

  const onSubmit = (data: { q?: string }) => {
    let searchValue = get(data, 'q') ?? '';
    if (selectedField === 'user.phone_number') {
      try {
        const phoneNumber = parsePhoneNumber(searchValue, 'US');
        if (phoneNumber.isValid()) {
          // Format as E.164 but include dashes
          searchValue = phoneNumber
            .format('E.164')
            .replace(/(\+\d)(\d{3})(\d{3})(\d{4})/, '$1 $2-$3-$4');
        }
      } catch (error) {
        console.error('Error parsing phone number:', error);
      }
    }
    if (
      searchValue.length >= 3 ||
      searchValue.length === 0 ||
      selectedField === 'id'
    ) {
      setNotification('');
      updateFilters(searchValue);
    } else {
      setNotification('Please enter at least 3 characters to search');
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex items-center space-x-2"
      >
        <Select value={selectedField} onValueChange={setSelectedField}>
          <SelectTrigger className="w-[140px] h-8">
            <SelectValue placeholder="Select a field" />
          </SelectTrigger>
          <SelectContent>
            {actualSearchFields.map((field) => (
              <SelectItem key={field.value} value={field.value}>
                {field.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Input
          placeholder={placeholder}
          className="h-8 w-[100px] lg:w-[250px]"
          {...form.register('q')}
        />
        <Button type="submit" variant="outline" size="icon" className="w-8 h-8">
          <SearchIcon className="w-4 h-4" />
        </Button>
        {notification && (
          <div className="text-red-500 text-xs">{notification}</div>
        )}
        <Separator orientation="vertical" className="h-8" />
      </form>
    </FormProvider>
  );
};
