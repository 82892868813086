import AppTitle from '@/modules/layout/components/app-title';
import { AssignToProviderButton } from '@/modules/ra-ui/components/AssignToProviderButton';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { Button } from '@/modules/ui/components/button';
import { Card, CardContent } from '@/modules/ui/components/card';
import { processAPIError } from '@/utils/processAPIError';
import { get, set, isEqual } from 'lodash';
import React, { FC } from 'react';
import {
  AutocompleteInput,
  DateInput,
  EditBase,
  Form,
  ReferenceInput,
  SelectInput,
  WithRecord,
  useNotify,
  usePermissions,
  useRefresh,
  FormDataConsumer,
  required,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import {
  PatientAsthmaSection,
  PatientCHFSection,
  PatientCOPDSection,
  PatientChronicKidneyDiseaseSection,
  PatientDemographics,
  PatientDiabetesSection,
  PatientGeneralDetails,
  PatientMedicalHistory,
  PatientRPMInfo,
} from './PatientCreateEdit';
import { sanitizeEmptyValuesCustom } from './sanitizeEmptyValuesCustom';

const filterUserName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

const filterProviderName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

export const PatientEdit: FC = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions } = usePermissions();
  const sourceRoot = '';
  const navigate = useNavigate();

  const transform = (data, { previousData }) => {
    if (get(data, 'tenant_id') === '') {
      delete data.tenant_id;
    }

    // Check that patient profile is changed, otherwise don't send
    if (isEqual(data.patient, previousData.patient)) {
      delete data.patient;
    }

    const data_2 = sanitizeEmptyValuesCustom(data, previousData);

    // Delete some items
    set(data_2, 'care_plan', undefined);

    if (get(data_2, 'patient.profile.risk_factors') === null) {
      set(data_2, 'patient.profile.risk_factors', []);
    }

    return data_2;
  };

  const onError = (error) => {
    notify(
      processAPIError(error) || error.message || 'ra.notification.http_error',
      { type: 'error' },
    );
    refresh();
  };

  const onSuccess = (data) => {
    notify(`Changes to patient ${data.user.full_name} saved`);
    refresh();
  };

  return (
    <EditBase
      {...props}
      resource="patients"
      transform={transform}
      redirect={false}
      mutationOptions={{ onError, onSuccess }}
      mutationMode="pessimistic"
      queryOptions={{
        refetchOnMount: 'always',
      }}
    >
      <div className="flex flex-col gap-1 w-full my-0">
        <AppTitle title={`Edit patient`} />
        <p className="text-base ">Edit patient profile</p>
      </div>
      <Form className="my-2">
        <Card className="mb-4">
          <CardContent>
            <PatientGeneralDetails sourceRoot={sourceRoot} userRoot={'user.'} />
            <PatientRPMInfo sourceRoot={sourceRoot}>
              {get(permissions, 'is_internal') ? (
                <ReferenceInput source="user.tenant_id" reference="providers">
                  <SelectInput
                    label="Provider"
                    optionText="name"
                    disabled
                    sx={{
                      width: 400,
                    }}
                    source="name"
                    helperText="Use the `Set Provider` button to change the provider."
                  />
                </ReferenceInput>
              ) : null}
              <WithRecord
                render={(record) => (
                  <FormDataConsumer>
                    {({ formData }) =>
                      formData.tenant_id || record?.user?.tenant_id ? (
                        <ReferenceInput
                          source="billing_provider_id"
                          reference="users"
                          filter={{
                            'role[ne]': 'patient',
                            'npi_number[ne]': null,
                            'is_provider[eq]': true,
                            'tenant_id[eq]':
                              formData.tenant_id || record?.user?.tenant_id,
                          }}
                        >
                          <AutocompleteInput
                            label="Billing Provider"
                            optionText="full_name"
                            parse={(v) => (v === '' ? null : v)}
                            helperText={false}
                            filterToQuery={filterProviderName}
                            fullWidth
                            validate={required()}
                          />
                        </ReferenceInput>
                      ) : null
                    }
                  </FormDataConsumer>
                )}
              />

              {get(permissions, 'is_internal') === true ? (
                <WithRecord
                  render={(record) => (
                    <ReferenceInput
                      source="enrolled_by_id"
                      reference="users"
                      filter={{
                        'role[ne]': `patient`,
                        'tenant_id[in]': [get(record, 'user.tenant_id'), null],
                      }}
                    >
                      <AutocompleteInput
                        filterToQuery={filterUserName}
                        label="Enrolled by"
                        optionText={(choice) => {
                          if (choice.is_internal) {
                            return `${choice.first_name} ${
                              choice.last_name
                            } (Vironix) ${
                              choice.credentials
                                ? `(${choice.credentials})`
                                : ``
                            }`;
                          }
                          return `${choice.full_name} ${
                            choice.credentials ? `(${choice.credentials})` : ``
                          }`;
                        }}
                        sx={{
                          width: 400,
                        }}
                        parse={(v) => (v === '' ? null : v)}
                        helperText={false}
                      />
                    </ReferenceInput>
                  )}
                />
              ) : null}
              {get(permissions, 'is_internal') === true ? (
                <DateInput
                  source="user.enrolled_on"
                  label="Enrolled on"
                  helperText={false}
                />
              ) : null}
            </PatientRPMInfo>
            <PatientDemographics sourceRoot={sourceRoot} />
            <PatientMedicalHistory sourceRoot={sourceRoot} />
            <PatientCHFSection sourceRoot={sourceRoot} />
            <PatientCOPDSection sourceRoot={sourceRoot} />
            <PatientAsthmaSection sourceRoot={sourceRoot} />
            <PatientDiabetesSection sourceRoot={sourceRoot} />
            <PatientChronicKidneyDiseaseSection sourceRoot={sourceRoot} />
          </CardContent>
        </Card>
        <div className="flex flex-row gap-2 justify-end">
          <AssignToProviderButton resource="users" />
          <Button variant="default" onClick={() => navigate(-1)} type="button">
            Back
          </Button>
          <SaveButton variant="default" label="Save" />
        </div>
      </Form>
    </EditBase>
  );
};
